/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: scroll;
}

.modal-content {
  background: white;
  padding: 25px;
  max-width: 600px;
  border-radius: 8px;
  position: relative;
}

.modal-title {
  text-align: center;
}

.modal-description {
  margin: 1em;
  list-style-type: disc;
}

.modal-img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-button {
  background-color: #ffd15c;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-repo-button {
  background-color: #24292e;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  line-height: 1;
  background: none;
  border: none;
  cursor: pointer;
  color: #24292e;
  font-weight: bold;
}

.modal-close-button {
  background-color: var(--first-color);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.github-icon {
  margin-right: 8px;
}
